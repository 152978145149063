import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path:"",
    loadChildren:'./../app/frontend/frontend.module#FrontendModule'
  },
  {
    path:"admin",
    loadChildren:'./../app/backend/backend.module#BackendModule'
  },
  {
    path: "**",
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
